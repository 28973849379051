<template>
  <div class="user">
    <div class="header" @click="goUrl('userInfo')">
      <div class="avatar">
        <van-image
          round
          :src="avatar"
          class="img"
        />
      </div>
      <div class="cont">
        <div class="phone">{{ phone }}</div>
      </div>
    </div>
    <div class="content">
      <div class="list">
        <van-cell v-for="item in menu" :key="item.id" :icon="item.icon" :title="item.title" is-link :value="item.value" :to="item.to" icon-prefix="icon" />
      </div>
    </div>
    <div class="footer">
      <van-button type="primary" size="normal" round block color="linear-gradient(136deg, #FFB367 0%, #FF7700 100%)" @click="goOut">安全退出</van-button>
    </div>
  </div>
</template>

<script>
import { getOssUrl } from '@/utils/oss'
import { mapGetters } from 'vuex'
import icon1 from '@/assets/user/manager@3x.png'
import icon2 from '@/assets/user/coupon@3x.png'
import icon3 from '@/assets/user/bank_card@3x.png'
import icon4 from '@/assets/user/share@3x.png'
import icon5 from '@/assets/user/income@3x.png'
import icon6 from '@/assets/user/complaint@3x.png'
import icon7 from '@/assets/user/setting@3x.png'
export default {
  data() {
    return {
      avatar: '',
      phone: '',
      menu: [
        { id: 1, title: '我的经理人', value: '', icon: icon1, to: 'userManager' },
        { id: 2, title: '我的卡券', value: '', icon: icon2, to: 'userCoupon' },
        { id: 3, title: '我的银行卡', value: '', icon: icon3, to: 'bankList' },
        { id: 4, title: '好友分享', value: '', icon: icon4, to: 'userShare' },
        { id: 5, title: '我的收益', value: '', icon: icon5, to: 'userIncome' },
        { id: 6, title: '我的投诉', value: '', icon: icon6, to: 'userComplaint' },
        { id: 7, title: '设置', value: '', icon: icon7, to: 'setting' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'userInfos'
    ])
  },
  async mounted() {
    this.avatar = await getOssUrl(this.userInfos.headImgUrl)
    this.phone = this.userInfos.eaUserName
  },
  methods: {
    goUrl(name) {
      this.$router.push({ name })
    },
    goOut() {
      this.$dialog.confirm({
        title: '提示',
        message: '若退出登录，再次使用需要重新登录，是否继续？',
        confirmButtonColor: '#FF7700'
      })
        .then(() => {
          this.$store.dispatch('user/loginOut')
          this.$router.push({ name: 'login' })
        })
        .catch(() => {
          // on cancel
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.user {
  position: relative;
  min-height: 100%;
  background: #f7f8fa;
  .header {
    position: relative;
    z-index: 99;
    display: flex;
    align-items: center;
    padding: 22px;
    color: #fff;
    background: linear-gradient(136deg, #FFB367 0%, #FF7700 100%);
    .avatar {
      .img {
        width: 54px;
        height: 54px;
        border-radius: 100px;
      }
    }
    .cont {
      padding-left: 10px;
      font-size: 16px;
      font-weight: bold;
    }
  }
  .content {
    .list {
      background: #fff;
      .van-cell {
        align-items: center;
      }
      .icon::v-deep {
        width: 30px;
        height: 30px;
        margin-right: 10px;
        .van-icon__image {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
  .footer {
    padding: 32px 40px;
  }
  .bg {
    position: absolute;
    z-index: 1;
    top: -180px;
    left: -50%;
    width: 200%;
    height: 300px;
    border-radius: 100%;
    background: linear-gradient(136deg, #FFB367 0%, #FF7700 100%);
  }
}
</style>
